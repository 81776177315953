<template>
  <div
    :class="['cms-media-image', {'ui-clickable': !!this.href}]"
    @click="onClick()"
    :style="style"
  >
    <img
      v-if="src"
      :src="src"
    />
    <div
      v-else
      class="cms-media-image-placeholder"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'cms-media-image',
  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },

    href: {
      type: String,
      required: false,
      default: null,
    },

    height: {
      type: [Number, String],
      required: false,
      default: 350,
    },

    sizing: {
      type: String,
      required: false,
      default: 'contain', // "cover" o "contain"
    },

    attachment: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    style() {
      return {};

      return {
        backgroundImage: this.src ? `url(${this.src})` : '',
        backgroundSize: this.sizing,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 0',
        backgroundAttachment: this.attachment,
        height: parseInt(this.height) + 'px',
      };
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
      if (!this.href) {
        return;
      }

      window.open(this.href, '_system');
    },
  },
};
</script>

<style lang="scss">
.cms-media-image {
  & > img {
    display: block;
    margin: auto;
    // width: 100%;
    max-width: 100%;
  }

  .cms-media-image-placeholder {
    width: 100%;
    max-width: 100%;
    height: 256px;
    border: 1px dashed #999;
    background-color: rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>