<template>
  <div
    class="cms-media-image-picker"
    :class="{'--empty': isEmpty, '--notempty': !isEmpty}"
  >
    <file-uploader
      :path="path"
      max-files="1"
      @success="onUploadSuccess"
      @error="onUploadError"
      accepted-files="image/*"
    >
      <div class="image-picker-face">
        <div class="image-picker-overlay">
          <ui-icon value="mdi:upload"></ui-icon>
          <p>Subir imagen</p>
        </div>
        <cms-media-image :src="value"></cms-media-image>
      </div>
    </file-uploader>
  </div>
</template>

<script>
import CmsMediaImage from '@/modules/cms/components/Media/Image/Image.vue';
import FileUploader from '@/modules/filesystem/components/File/FileUploader.vue';
import { UiIcon } from '@/modules/ui/components';

export default {
  name: 'cms-media-image-picker',
  components: { CmsMediaImage, FileUploader, UiIcon },
  props: {
    path: {
      type: String,
      required: true
    },

    value: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    isEmpty() {
      return !this.value || !this.value.trim();
    }
  },

  methods: {
    onUploadSuccess(e) {
      this.$emit('input', e[0].url);
    },

    onUploadError(err) {
      alert('Error subiendo archivo');
      console.log('Error subiendo archivo', err);
    }
  }
};
</script>

<style lang="scss">
.cms-media-image-picker {
  .cms-media-image-picker-form {
    input {
      width: 100%;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .image-picker-face {
    position: relative;

    .image-picker-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      opacity: 0.7;
      text-shadow: 0 1px 2px #000, 0 0 2px #000, -1px 0 2px #000, 1px 0 2px #000;
    }
  }

  &.--notempty {
    .image-picker-overlay {
      transition: opacity var(--ui-duration-smooth);
      opacity: 0;
    }

    &:hover {
      .image-picker-overlay {
        opacity: 1;
      }
    }
  }
}
</style>